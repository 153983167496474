import { lazy } from 'react'
import { Redirect } from 'react-router'
import { RouteConfig } from 'react-router-config'

const SeaPC = lazy(() => import('@/pages/sea/v1/pc-site'))
const SeaLandingBp = lazy(() => import('@/pages/sea/landing_bp/pc-site'))
const SeaAdLanding = lazy(() => import('@/pages/sea/ad_landing'))
const SeaMobile = lazy(() => import('@/pages/sea/v1/m-site'))
const Transit = lazy(() => import('@/pages/sea/pay/pc/transit'))
const TransitMobile = lazy(() => import('@/pages/sea/pay/mobile/transit'))
const ShopStore = lazy(() => import('@/pages/sea/pay/pc/shopStore'))
const ShopStoreMobile = lazy(() => import('@/pages/sea/pay/mobile/shopStore'))
const ConfirmOrder = lazy(
  () => import('@/pages/sea/pay/pc/shopStore/subpages/confirmOrder')
)
const ConfirmOrderMobile = lazy(
  () => import('@/pages/sea/pay/mobile/shopStore/subpages/confirmOrder')
)
const WaitOrder = lazy(
  () => import('@/pages/sea/pay/pc/shopStore/subpages/waitOrder')
)
const WaitOrderMobile = lazy(
  () => import('@/pages/sea/pay/mobile/shopStore/subpages/waitOrder')
)
const SelectOrder = lazy(
  () => import('@/pages/sea/pay/pc/shopStore/subpages/selectOrder')
)
const SelectOrderMobile = lazy(
  () => import('@/pages/sea/pay/mobile/shopStore/subpages/selectOrder')
)
const FailOrder = lazy(
  () => import('@/pages/sea/pay/pc/shopStore/subpages/failOrder')
)
const FailOrderMobile = lazy(
  () => import('@/pages/sea/pay/mobile/shopStore/subpages/failOrder')
)

const OfficialLayout = lazy(() => import('@/pages/sea/official/layout'))

const routes: RouteConfig[] = [
  {
    path: '/',
    component: OfficialLayout,
    routes: [
      // 封面
      {
        path: '/',
        component: lazy(() => import('@/pages/sea/official/pc-site')),
        exact: true,
      },
      {
        path: '/activity/anniversary/:vroleid',
        component: lazy(() => import('@/pages/sea/activities/anniversary')),
      },
      {
        path: '/activity/new-group-reservation',
        component: lazy(() => import('@/pages/sea/activities/newGroup')),
      },
      // {
      //   path: '/third-job-act',
      //   component: lazy(
      //     () => import('@/pages/sea/activities/third_job_act/pc-site')
      //   ),
      //   exact: true,
      // },
      {
        path: '/reservation/sakura2024',
        component: lazy(() => import('@/pages/sea/reservation/sakura2024')),
      },
      {
        path: '/activity/sakura2024',
        component: lazy(() => import('@/pages/sea/activities/sakura2024')),
      },
      {
        path: '/home',
        component: lazy(() => import('@/pages/sea/official/pc-site')),
      },
      {
        path: '/news',
        component: lazy(
          () => import('@/pages/sea/official/pc-site/subpages/news-home')
        ),
        exact: true,
      },
      {
        path: '/news/:id',
        component: lazy(
          () => import('@/pages/sea/official/pc-site/subpages/news-detail')
        ),
        exact: true,
      },
      {
        path: '/guideline/:id',
        component: lazy(
          () => import('@/pages/sea/official/pc-site/subpages/guideline-detail')
        ),
        exact: true,
      },
      {
        path: '/landing_bp',
        component: SeaLandingBp,
        exact: true,
      },
      {
        path: '/ad_landing',
        component: SeaAdLanding,
        exact: true,
      },
      {
        path: '/odin_final',
        component: lazy(() => import('@/pages/sea/odin_final')),
        exact: true,
      },
      {
        path: '/1st-anniversary',
        component: lazy(
          () => import('@/pages/sea/activities/1st-annniversary-reserve')
        ),
        exact: true,
      },
      {
        path: '/odin_s2',
        component: lazy(() => import('@/pages/sea/odin_s2')),
        exact: true,
      },
      {
        path: '/m/odin_s2',
        component: lazy(() => import('@/pages/sea/odin_s2')),
        exact: true,
      },
      {
        path: '/m/odin_s2',
        component: lazy(() => import('@/pages/sea/odin_s2')),
        exact: true,
      },
      {
        path: '/match-result',
        component: lazy(() => import('@/pages/sea/activities/match-result')),
        exact: true,
      },
      {
        path: '/discord/login-bind',
        component: lazy(() => import('@/pages/sea/discord_login')),
        exact: true,
      },
      /* 支付--pc */
      // {
      //   path: '/transit',
      //   exact: true,
      //   component: Transit,
      // },
      // {
      //   path: '/shopStore2',
      //   component: ShopStore,
      //   routes: [
      //     {
      //       path: '/shopStore2/selectOrder',
      //       component: SelectOrder,
      //     },
      //     {
      //       path: '/shopStore2/confirmOrder',
      //       component: ConfirmOrder,
      //     },
      //     {
      //       path: '/shopStore2/waitOrder',
      //       component: WaitOrder,
      //     },
      //     {
      //       path: '/shopStore2/failOrder',
      //       component: FailOrder,
      //     },
      //   ],
      // },
      // // 预约页
      {
        path: '/doram-kingdom-reserve',
        component: lazy(() => import('@/pages/sea/valentineServerReserve')),
        exact: true,
      },
      // 测试fb 数数上报数据
      {
        path: '/doram-kingdom-reserve-fb',
        component: lazy(() => import('@/pages/sea/valentineServerReserve/FB')),
        exact: true,
      },
      {
        path: '/ten-people-race',
        component: lazy(() => import('@/pages/sea/tenPeopleRace')),
        exact: true,
      },
      {
        path: '/For-Honor-reserve',
        component: lazy(() => import('@/pages/sea/anniversaryReserve')),
        exact: true,
      },
      // 游戏内跳转不支持  路由不能有横线（-）下划线（_）
      {
        path: '/tenPeopleRace',
        component: lazy(() => import('@/pages/sea/tenPeopleRace')),
        exact: true,
      },
      // // 投放页面
      // {
      //   path: '/valentine-server-put',
      //   component: lazy(() => import('@/pages/sea/valentineServerReserve/put')),
      //   exact: true,
      // },
      // {
      //   path: '/shopStore',
      //   component: lazy(() => import('@/pages/sea/pay_new/pc')),
      //   routes: [
      //     {
      //       path: '/shopStore/selectOrder',
      //       component: lazy(() => import('@/pages/sea/pay_new/pc/giftList')),
      //     },
      //     {
      //       path: '/shopStore/help-center',
      //       component: lazy(() => import('@/pages/sea/pay_new/pc/helpCenter')),
      //     },
      //     {
      //       path: '/shopStore/pay',
      //       component: lazy(() => import('@/pages/sea/pay_new/pc/pay')),
      //     },
      //     {
      //       path: '/shopStore/wait-order',
      //       component: lazy(() => import('@/pages/sea/pay_new/pc/waitOrder')),
      //     },
      //     {
      //       path: '/shopStore/fail-order',
      //       component: lazy(() => import('@/pages/sea/pay_new/pc/failOrder')),
      //     },
      //   ],
      // },
      /* 移动端 */
      {
        path: '/m',
        component: lazy(() => import('@/pages/sea/components/empty-layout')),
        routes: [
          // 封面
          {
            path: '/m',
            component: lazy(() => import('@/pages/sea/official/m-site')),
            exact: true,
          },
          {
            path: '/m/home',
            component: lazy(() => import('@/pages/sea/official/m-site')),
          },
          {
            path: '/m/news',
            component: lazy(
              () => import('@/pages/sea/official/m-site/subpages/news-home')
            ),
            exact: true,
          },
          {
            path: '/m/activity/sakura2024',
            component: lazy(
              () => import('@/pages/sea/activities/sakura2024/m-site')
            ),
            exact: true,
          },
          {
            path: '/m/news/:id',
            component: lazy(
              () => import('@/pages/sea/official/m-site/subpages/news-detail')
            ),
            exact: true,
          },
          {
            path: '/m/guideline/:id',
            component: lazy(
              () =>
                import('@/pages/sea/official/m-site/subpages/guideline-detail')
            ),
            exact: true,
          },
          {
            path: '/m/1st-anniversary',
            component: lazy(
              () => import('@/pages/sea/activities/1st-annniversary-reserve')
            ),
          },
          {
            path: '/m/odin_final',
            component: lazy(() => import('@/pages/sea/odin_final')),
            exact: true,
          },
          // {
          //   path: '/m/third-job-act',
          //   component: lazy(
          //     () => import('@/pages/sea/activities/third_job_act/m-site')
          //   ),
          //   exact: true,
          // },
          {
            path: '/m/landing_bp',
            component: lazy(() => import('@/pages/sea/landing_bp/m-site')),
            exact: true,
          },
          /* 支付--mobile */
          // {
          //   path: '/m/transit',
          //   exact: true,
          //   component: TransitMobile,
          // },
          // {
          //   path: '/m/shopStore2',
          //   component: ShopStoreMobile,
          //   routes: [
          //     {
          //       path: '/m/shopStore2/selectOrder',
          //       component: SelectOrderMobile,
          //     },
          //     {
          //       path: '/m/shopStore2/confirmOrder',
          //       component: ConfirmOrderMobile,
          //     },
          //     {
          //       path: '/m/shopStore2/waitOrder',
          //       component: WaitOrderMobile,
          //     },
          //     {
          //       path: '/m/shopStore2/failOrder',
          //       component: FailOrderMobile,
          //     },
          //   ],
          // },
          // {
          //   path: '/m/shopStore',
          //   component: lazy(() => import('@/pages/sea/pay_new/mobile')),
          //   routes: [
          //     {
          //       path: '/m/shopStore/selectOrder',
          //       component: lazy(
          //         () => import('@/pages/sea/pay_new/mobile/giftList')
          //       ),
          //     },
          //     {
          //       path: '/m/shopStore/help-center',
          //       component: lazy(
          //         () => import('@/pages/sea/pay_new/mobile/helpCenter')
          //       ),
          //     },
          //     {
          //       path: '/m/shopStore/pay',
          //       component: lazy(() => import('@/pages/sea/pay_new/mobile/pay')),
          //     },
          //     {
          //       path: '/m/shopStore/wait-order',
          //       component: lazy(
          //         () => import('@/pages/sea/pay_new/mobile/waitOrder')
          //       ),
          //     },
          //     {
          //       path: '/m/shopStore/fail-order',
          //       component: lazy(
          //         () => import('@/pages/sea/pay_new/mobile/failOrder')
          //       ),
          //     },
          //   ],
          // },
          {
            path: '/m/vs',
            component: lazy(() => import('@/pages/sea/vs/m-site')),
          },
          {
            path: '*',
            render: () => <Redirect to="/m" />,
          },
        ],
      },
      /* 赛事--pc */
      // {
      //   path: '/pc/vs',
      //   render: () => <Redirect to="/vs" />,
      // },
      {
        path: '/vs',
        component: lazy(() => import('@/pages/sea/vs/pc-site')),
      },
      {
        path: '*',
        render: () => <Redirect to="/" />,
      },
    ],
  },
]

export default routes
